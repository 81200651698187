/* import this file to where you define the color variables for the environment */
nav.container-fluid {
  padding: 0;
}
.navbar-header.main-menu {
  float: none;
}
.navbar-header.main-menu button.navbar-toggle {
  color: white;
}
.navbar-header.main-menu .navbar-collapse {
  float: none;
  margin: auto;
  overflow: hidden;
}
.navbar-header.main-menu .navbar-nav > .open > a,
.navbar-header.main-menu ul.nav > li > a:hover {
  background: transparent;
}
.navbar-header.main-menu .navbar-default {
  background-color: transparent;
  margin-bottom: 0;
  text-align: center;
  font-size: 15px;
}
.navbar-header.main-menu ul.inline-nav {
  margin-bottom: 0;
}
.navbar-header.main-menu ul.inline-nav li {
  display: inline-block;
}
.navbar-header.main-menu ul.inline-nav a {
  height: 50px;
  padding: 15px;
}
.navbar-header.main-menu ul.mega-menu-submenu {
  margin-left: 7px;
}
.navbar-header.main-menu ul.mega-menu-submenu li {
  width: 100%;
}
.navbar-header.main-menu ul.mega-menu-submenu a {
  font-family: FontAwesome, 'Open Sans', sans-serif !important;
}
.navbar-header.main-menu a {
  font-weight: 400 !important;
  color: white !important;
  display: block;
  width: 100%;
  height: 100%;
}
.navbar-header.main-menu ul {
  list-style-type: none;
  padding: 0;
}
@media (max-width: 1000px) {
  .navbar-header.main-menu a {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 1000px) {
  #patient-tabs a {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 900px) {
  #patient-tabs a {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
label {
  overflow: visible;
}
form div.form-group {
  margin-bottom: 5px !important;
  overflow: hidden;
}
.form-group .help-icon {
  padding-top: 10px;
}
input.datepicker {
  width: 110px !important;
  padding-left: 12px;
}
input[type=time] {
  width: 100px;
}
.form-group .form-control.number {
  max-width: 100px !important;
}
.form-group select.form-control {
  float: left;
}
.form-group .default-checkbox {
  padding-top: 7px !important;
}
.form-group.required .control-label,
.form-inline .control-label.required,
.form-inline .help-inline.required {
  padding-right: 9px;
}
.form-group.required .control-label:after,
.form-inline .control-label.required:after,
.form-inline .help-inline.required:after {
  content: "*";
  color: red;
  font-size: smaller;
  position: relative;
  top: -4px;
}
.checkbox-group {
  margin-top: 9px;
}
.sub-form {
  padding-bottom: 15px;
}
.read-only-value {
  display: inline-block;
  margin-top: 7px;
  font-style: italic;
}
.form-horizontal .radio-list label.radio {
  padding-top: 7px;
}
div.radio {
  margin-right: 5px;
}
.question {
  margin-bottom: 0;
  font-weight: bold;
  text-align: left !important;
}
.answer {
  margin-bottom: 10px;
}
#questionnaire-form .modal-body {
  padding-left: 30px;
  padding-right: 30px;
}
#questionnaire-form .col-md-12 {
  padding-left: 0;
}
#questionnaire-form select.form-control {
  width: auto;
}
#questionnaire-form input.form-control {
  width: 80%;
}
.back-button {
  position: relative;
  background: #3598dc;
  border: 1px solid #3598dc;
  font-size: 14px;
  padding: 2px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px !important;
  color: white;
  top: 15px;
  float: left;
}
.back-button:hover {
  color: white;
  text-decoration: none;
}
.back-button:after,
.back-button:before {
  right: 95%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.back-button:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #3598dc;
  border-width: 12px;
  margin-top: -10px;
}
.back-button:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: #3598dc;
  border-width: 13px;
  margin-top: -13px;
}
ul.nav button,
ul.nav input {
  background-color: transparent;
  border: 0;
  padding: 8px 14px;
  margin-left: 1px;
}
.dropdown-menu li input:hover {
  text-decoration: none;
  background-image: none;
  background-color: #f6f6f6;
  color: #555;
  -webkit-filter: none;
  filter: none;
}
.non-changing-link {
  color: black !important;
  display: inline-block !important;
  text-decoration: none !important;
}
div.light.notepad-message {
  overflow: hidden;
  border: 1px solid lightgrey;
  border-radius: 3px !important;
  padding: 0 !important;
}
div.light.notepad-message .content {
  padding: 15px;
  overflow: hidden;
}
div.light.notepad-message .time {
  float: right;
  color: lightgray;
}
div.light.notepad-message .avatar {
  width: 15px;
}
div.light.notepad-message .replies {
  background-color: #f6f7f9;
  padding: 0 20px 0 20px;
}
div.light.notepad-message .replies td {
  vertical-align: top;
  margin-bottom: 10px;
  padding: 2px;
  padding-top: 6px;
}
div.light.notepad-message .replies .time {
  white-space: nowrap;
}
div.light.notepad-message table {
  width: 100%;
}
div.light.notepad-message .do-reply {
  border-top: 1px solid lightgrey;
}
div.light.notepad-message .do-reply td {
  padding-top: 10px;
}
div.light.notepad-message .header {
  overflow: hidden;
}
div.light.notepad-message .header .subject {
  font-weight: bold;
  float: none;
  margin-left: 10px;
  font-size: 110%;
}
.dropzone {
  padding: 5px !important;
}
.dropzone .dz-preview {
  margin: 4px;
}
.non-droppable-day {
  background-color: #ffaaaa;
  cursor: not-allowed;
}
.ui-sortable-helper {
  pointer-events: none;
}
body.zoom-in > div {
  zoom: 115%;
  -moz-transform: scale(1.15);
  -moz-transform-origin: 0 0;
}
body.zoom-out > div {
  zoom: 85%;
  -moz-transform: scale(0.85);
  -moz-transform-origin: 0 0;
}
h2 {
  font-size: 20px;
}
h5 {
  font-weight: bold;
  margin-top: 20px;
}
.checked-color {
  color: #75a600;
}
div.portlet h1 {
  margin-top: 0px;
}
.page-footer {
  background: #eff3f8;
}
.timeline > li .timeline-time span.time {
  line-height: 20px;
  font-size: 20px;
}
.timeline .timeline-icon {
  padding-top: 18px;
  padding-left: 25px;
  font-size: 30px;
}
img.avatarSmall {
  width: 42px;
  height: 42px;
  margin-right: 10px;
  padding: 0px !important;
}
img.avatarTiny {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
img.avatarLarge {
  width: 90px;
  height: 90px;
  margin-right: 10px;
}
div.trainingPreviewSmall {
  clear: left;
  margin-bottom: 5px;
  min-height: 40px;
}
div.trainingPreviewSmall {
  clear: left;
  margin-bottom: 5px;
  min-height: 40px;
}
img.trainingAvatarSmall {
  width: 36px;
  height: 36px;
  float: left;
  border: solid 1px #000000;
}
img.trainingAvatarXs {
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 5px;
  border: solid 1px #000000;
  margin-bottom: 3px;
}
.comments {
  font-size: 90%;
  color: #888888;
}
span.date-round {
  color: white;
  font-size: 16px;
  border-radius: 50% 50% 50% 50% !important;
  width: 50px;
  height: 50px;
  padding-top: 5px;
  font-weight: bold;
  display: block;
  text-align: center;
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
}
.loginWrapper {
  width: 350px;
  margin: auto;
  padding: 10px;
  background: white;
  border: solid 1px #999999;
}
div.light {
  padding: 5px 10px 10px !important;
}
.trainingTable {
  margin-bottom: 5px !important;
}
.trainingTable .trainingPreviewSmall {
  border: solid 1px #cccccc;
  border-left: solid 4px #aaaaaa;
  background: #dddddd;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 2px;
  padding-left: 4px;
}
div.in-the-past {
  background: #eeeeee !important;
  color: grey;
}
b.in-the-past {
  color: grey;
}
.trainingTable .started {
  border-left: solid 4px #c63927 !important;
}
.trainingTable .finished {
  border-left: solid 4px #75A600 !important;
}
.trainingTable .patient-initiated {
  border-left: solid 4px #4b8df8 !important;
}
.trainingTable .unmutable {
  cursor: auto !important;
}
.trainingTable .trainingPreviewTiny {
  border-left: solid 2px #aaaaaa;
  background: #dddddd;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 2px;
  padding-left: 4px;
  font-size: 90%;
}
.trainingTable ul {
  margin-left: 0px;
  padding-left: 15px;
}
.login {
  background-color: #eff3f8 !important;
}
.login .content {
  background: #fff;
}
.login .content .input-icon {
  border-left: 2px solid #35aa47 !important;
  border-radius: 4px;
}
.login .content .form-control {
  background: #fff;
}
.login .content .form-actions .btn-login {
  margin-top: -30px;
}
.login .content h3 {
  font-size: 24px;
}
.login .checkbox {
  width: 130px;
}
.fit {
  padding-right: 3px;
  width: 1px;
}
div.original_messages {
  color: #999999;
}
tr.unread * {
  font-weight: bold !important;
}
.tooltip-inner {
  white-space: pre-wrap;
}
.progress {
  width: 300px;
  border: 1px solid #d1d1d1;
  position: relative;
  height: 25px;
  margin-top: 4px;
}
.progress .percent {
  position: absolute;
  left: 20%;
  padding: 3px;
  color: #000000;
}
.progress .bar {
  height: 23px;
  margin-bottom: 3px;
}
#category_selector {
  margin-top: 25px;
}
#category_selector li {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: right;
  overflow: hidden;
  font-size: 14px !important;
  padding: 8px 10px;
}
#category_selector li:hover {
  background: #eeeeee;
}
#category_selector li.active {
  background: #87a9c7;
  color: white;
}
#feedback {
  position: fixed;
  bottom: 0px;
  right: 200px;
  width: 320px;
}
#feedbackButton {
  cursor: pointer;
  background: black;
  color: white;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  width: 120px;
  margin-left: 20px;
  box-shadow: 4px 4px 4px #888888;
}
#feedbackForm {
  border: solid 1px black;
  padding: 5px;
  border-bottom: none;
  background: white;
  box-shadow: 4px 4px 4px #888888;
}
.trainingTable th {
  color: white !important;
}
.compact-table .form-control {
  padding: 2px 3px;
  height: 24px;
}
.compact-table td {
  padding: 4px !important;
  border-top: none !important;
}
.compact-table h4 {
  margin-top: 6px;
  margin-bottom: 2px;
}
.compact-table .form-body {
  padding: 5px 30px;
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}
.coming-day .trainingPreviewSmall:not(.finished):not(.started):not(.patient-initiated) {
  cursor: move !important;
}
.pointer {
  cursor: pointer;
}
#graded-activity-graph {
  height: 500px;
  font-size: 11px;
}
.questionnaire-single .form-group,
.questionnaire-single .MultiButton {
  text-align: center;
}
.sm-border-bottom {
  border-bottom: 1px solid lightgray;
}
td.ion-ajax .irs .irs {
  margin-top: -21px;
}
td.ion-ajax {
  padding-bottom: 3px !important;
}
.font-size-selection li.active a {
  background-color: #eee;
}
span.gray-note {
  color: #404040;
}
.font-awesome-submit {
  font-family: FontAwesome, 'Open Sans', sans-serif;
  font-style: normal;
}
.disabledTab {
  pointer-events: none;
}
.exercise-thumbnail-small {
  vertical-align: middle;
  display: inline-block;
  height: 30px;
}
.exercise-thumbnail {
  height: 36px;
  width: 36px;
}
.exercise-comment {
  position: relative;
  bottom: 2px;
}
.title-wrapper {
  min-height: 40px;
  margin-bottom: 10px;
}
#edit-exercise-instructions-form .title {
  width: 310px;
}
#edit-exercise-instructions-form .instructions {
  width: 270px;
}
#edit-exercise-instructions-form .instructions .form-control {
  padding: 4px 12px !important;
  line-height: 15px !important;
}
#edit-exercise-instructions-form td {
  vertical-align: middle !important;
}
.clickable-block:hover {
  cursor: pointer;
}
.img-small {
  width: 22px;
  height: 22px;
}
.question_hidden {
  display: none;
}
#stopwatch {
  background-image: url("/images/stopwatch_large.png");
  background-size: 80%;
  background-repeat: no-repeat;
}
.small-stopwatch {
  margin-top: 10px;
  margin-right: 4px;
}
.list-inline > li {
  white-space: nowrap;
}
div.dataTables_filter input.custom-search {
  width: 300px;
  height: 20px;
}
.page-header .page-header-top .menu-toggler {
  margin: 0px !important;
  margin-top: 10px !important;
}
.clickable-text {
  border: 0px solid black;
  background: transparent;
}
.alert > p + p {
  margin-top: 0px;
}
.modal-small {
  width: 500px;
  margin-left: -250px;
}
.modal-medium {
  width: 600px;
  margin-left: -300px;
}
.modal-large {
  width: 800px;
  margin-left: -400px;
}
.modal-extra-large {
  width: 1000px;
  margin-left: -500px;
}
div.modal div.modal-body {
  overflow-x: hidden;
}
table.align-top td {
  vertical-align: top;
}
.template-edit .help-inline {
  margin-left: 10px;
}
.help-inline {
  padding: 8px;
}
div.note-editor {
  border: solid 1px #aaaaaa !important;
  float: left;
  margin-left: 15px;
  width: 95%;
}
div.note-editor textarea {
  display: none;
}
div.note-toolbar,
div.note-statusbar {
  background: #dddddd !important;
}
.table.table-no-border td {
  border: 0px solid #000000;
}
.table.table-no-padding td {
  padding: 0px;
}
a.timeline-link {
  color: #578ebe;
}
.positive-feedback {
  font-size: 25px;
  margin-top: 20px;
  border-style: solid;
  border-color: #91C400;
  border-width: 5px;
  padding: 10px;
}
.no-border-right {
  border-right: 0 !important;
}
th.past-day {
  background-color: #aaaaaa !important;
}
.panel-title a.accordion-toggle {
  display: block;
  width: 100%;
}
.disabledElement {
  opacity: .5;
  pointer-events: none;
}
.lookLikeLink {
  background: none;
  border: none;
  display: inline;
  text-align: left;
}
.lookLikeLink:hover {
  text-decoration: underline;
}
.video-js {
  width: 100%;
  position: relative;
}
.no-actions {
  pointer-events: none;
}
.text-light-grey,
.text-light-grey:active,
.text-light-grey:visited,
.text-light-grey:hover,
.text-light-grey:focus {
  color: lightgrey;
}
.time-line-with-help-badge {
  margin-left: 10px;
  background-color: white;
  padding: 2px;
  padding-left: 4px;
  padding-right: 6px;
  font-size: smaller;
  border-radius: 10px !important;
  border-color: #91C400;
  border-style: solid;
  border-width: 1px;
}
.modal-dialog {
  width: auto;
  margin: 0px auto;
}
input[type="number"] {
  width: 65px;
}
input.three-numbers[type="number"] {
  width: 75px;
}
[class*=" fa-"]:not(.fa-stack) {
  font-size: inherit;
}
.hidden-actions-overlay {
  display: none;
  position: absolute;
  right: 10px;
  background-color: black;
  opacity: 0.9;
  color: white;
  border-radius: 20px !important;
  padding: 0 10px;
}
.hidden-actions-overlay button {
  margin: 0 !important;
}
.hidden-actions-overlay button:hover {
  background-color: #555555;
}
.fa-action-button {
  background: none;
  border: none;
  display: inline-block;
  width: 30px;
  height: 30px;
  float: left;
  font-size: 16px !important;
  margin-left: 5px;
}
.fa-action-button:hover {
  background: rgba(0, 0, 0, 0.1);
}
.no-padding {
  padding: 0px !important;
}
.no-margin {
  margin: 0px !important;
}
table.my-goals td.space-under {
  padding-bottom: 5px;
}
table.my-goals .goal-small-info {
  font-size: 12px;
  color: #808080;
}
form.inline {
  display: inline;
}
form.inline button.btn-link,
div.goal-small-info button {
  padding: 0px;
}
td.base-program-day {
  font-size: 10px;
}
.info-mouse-over {
  cursor: default;
}
.clickable-ajax {
  cursor: pointer;
}
.view-message {
  width: 180px;
}
/**
* Pace indicator
*/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #29d;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}
@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.jstree-default .jstree-clicked,
.jstree-default .jstree-wholerow-clicked,
.jstree-default .jstree-hovered {
  background: none;
}
.jstree-default {
  overflow: hidden;
}
.jstree-default.blue-selected .selected {
  background-color: #87a9c7 !important;
}
.js-tree-training.jstree-default.blue-selected .selected {
  background-color: transparent !important;
}
.js-tree-training.jstree-default.blue-selected li[aria-selected="true"] > a {
  background-color: #87a9c7 !important;
}
.jstree-default .jstree-anchor {
  height: auto;
  white-space: normal;
}
.input-xs {
  height: 20px;
  padding: 5px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
ul.history li {
  margin-bottom: 3px;
}
.help-page img {
  border: 1px solid lightgray;
  margin-bottom: 10px;
}
.center {
  float: none;
  margin: 0 auto;
}
.small-text {
  font-size: 12px;
  position: relative;
  bottom: 15px;
}
td > ul.errors {
  padding: 0px !important;
}
.table-small-bot-margin > table {
  margin-bottom: 10px;
}
.xs-top-margin {
  margin-top: 8px;
}
.small-top-margin {
  margin-top: 10px;
}
.img-med {
  width: 40px;
}
.ajax-status {
  padding-left: 0px;
}
.fa {
  font-family: FontAwesome, 'Open Sans', sans-serif;
}
a.copy-baseprogram-tasks {
  margin: 5px !important;
}
h4.modal-title {
  vertical-align: middle;
  line-height: 32px;
}
.copy-day {
  font-size: 14px;
  position: absolute;
  left: 5%;
  bottom: 7px;
  width: 90%;
  text-align: center;
  vertical-align: middle;
  line-height: 14px;
  display: none;
}
.copy-day a {
  display: none;
}
td.training-table-day {
  font-size: 80%;
  position: relative;
  padding-bottom: 30px !important;
}
div.checker {
  margin-left: 0;
}
.modal-body {
  font-size: 14px;
}
ul.has-error > li {
  color: red;
}
.text-center th {
  text-align: center;
}
.full-width {
  width: 100%;
}
.form-inline .checker {
  padding-top: 3px;
}
.form-group .now-or-later {
  margin-top: 10px;
  padding: 0;
}
.text-input {
  padding-right: 10px;
}
.date-input {
  width: 120px;
  float: left;
  padding-left: 15px;
}
.small-margin-left {
  margin-left: 20px !important;
}
.med-padding-left {
  padding-left: 35px;
}
button.help-instruction-video {
  border-radius: 10px !important;
  font-size: 10px;
  background-color: transparent;
  vertical-align: top;
  color: black;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: normal;
}
.density-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px;
}
.density-table td {
  height: 20px;
  width: 25px;
  border-radius: 5px !important;
}
span.actions {
  float: right;
}
#activityTable td {
  cursor: pointer;
}
.pre-input-toggler {
  width: 100%;
  font-size: larger !important;
  height: 100%;
  vertical-align: middle;
  line-height: 32px !important;
  color: black;
}
.pre-input-toggler:hover {
  text-decoration: none;
  color: black;
}
label.search-label {
  width: 175px;
}
.show-questionnaire-item:hover {
  cursor: pointer;
}
.item-row.active,
.item-row.active td {
  background: rgba(0, 0, 0, 0.2) !important;
}
.item-row:hover,
table.table tr.item-row.active:hover td {
  background: rgba(0, 0, 0, 0.1) !important;
}
.calculation_string {
  font-family: console;
  font-weight: bold;
}
.calculation_string .item {
  background: #eeeeee;
  border: solid 1px #cccccc;
  padding: 3px;
  margin-top: -3px;
  margin-bottom: -3px;
  border-radius: 3px;
}
.calculation_string .operator {
  color: blue;
}
.calculation_string .function {
  color: green;
}
/** Also allow to create dropdown with buttons and not only with a*/
.dropdown-menu li button {
  padding: 8px 14px;
  color: #555;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: 300;
  line-height: 18px;
  white-space: nowrap;
  background: transparent;
  width: 100%;
  border: 0;
  text-align: left;
}
.dropdown-menu li button:hover,
.dropdown-menu .active button,
.dropdown-menu .active button:hover {
  text-decoration: none;
  background-image: none;
  background-color: #f6f6f6;
  color: #555;
  -webkit-filter: none;
  filter: none;
}
span.circle-avatar {
  display: inline-block;
  border-radius: 50% 50% 50% 50% !important;
  background: #858585;
  color: #FFFFFF;
  text-align: center;
  font-weight: bold;
}
span.circle-avatar.avatarLarge {
  width: 90px;
  height: 90px;
  padding-top: 18px;
  font-size: 50px;
  margin-right: 10px;
  line-height: 100%;
}
span.circle-avatar.avatarSmall {
  width: 45px;
  height: 45px;
  padding-top: 9px;
  font-size: 20px;
  margin-right: 10px;
}
span.circle-avatar.avatarTiny {
  width: 22px;
  height: 22px;
  padding-top: 3px;
  font-size: 11px;
}
.top-menu .avatarSmall {
  margin-top: -8px !important;
}
table tr.ui-sortable-helper {
  display: table !important;
}
.fa.fa-crown {
  background-image: url("/images/crown.png");
  background-size: cover;
  width: 16px;
  height: 16px;
}
img.large-img {
  max-width: 480px;
  max-height: 480px;
}
.error-code {
  margin-top: 40px;
  color: gray;
}
img.enlarge {
  max-width: 500px;
  max-height: 240px;
  cursor: pointer;
  display: block;
}
a.fc-time-grid-event.fc-v-event:hover {
  z-index: 10 !important;
}
td.fc-widget-content {
  cursor: pointer;
}
.btn.blue.blue-outline {
  background: #FFFFFF !important;
  color: #3598dc !important;
  border: solid 1px #3598dc !important;
}
.btn.blue.blue-outline:hover {
  background: #3598dc !important;
  color: #FFFFFF !important;
}
table.table tr:last-child {
  border-bottom: solid 1px #ddd;
}
#content-items-index_wrapper .col-md-6 {
  width: 100% !important;
}
#exercises-index_wrapper .col-md-6 {
  width: 100% !important;
}
a.btn {
  background-color: #DDDDDD;
}
.bootstrap-switch .checker {
  display: none;
}
.bootstrap-switch-mini {
  width: 60px !important;
}
.small-margin-right {
  margin-right: 5px;
}
div.caption {
  margin-bottom: 0px;
}
div.caption h1 {
  margin-bottom: 5px;
}
table.has-error {
  border: 2px solid red;
}
p.background {
  border: solid 1px #cccccc;
  background: #eeeeee;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}
h5 {
  text-align: center;
  border-bottom: solid 4px #cccccc;
  height: 12px;
  margin-left: 40px;
  margin-right: 40px;
}
h5 span {
  background: #FFFFFF;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14pt;
  text-transform: uppercase;
  letter-spacing: 1px;
}
div.storeItem {
  border: solid 1px #cccccc;
  margin-bottom: 10px;
}
div.storeItem .visual {
  height: 180px;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: solid 1px #cccccc;
}
div.storeItem .visualSplit {
  height: 120px;
  overflow-y: hidden;
  overflow-x: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: solid 1px #cccccc;
}
div.storeItem .visual img {
  width: 100%;
  margin: 0px;
  padding: 0px;
}
div.storeItem .info {
  padding: 5px;
}
div.storeItem .infoRow {
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
}
.heroicon-outline {
  fill: #626262;
}
.heroicon-shadows {
  fill: #626262;
  opacity: .4;
}
.heroicon-component-fill {
  fill: white;
}
.heroicon-lightbulb-bulb {
  fill: #fffcb7;
}
p.spaced {
  line-height: 1.6;
}
img.star {
  margin-top: 20px;
  margin-left: -24px;
  margin-right: 0px;
  width: 24px;
  margin-right: 10px;
  cursor: pointer;
}
.small-padding-right {
  padding-right: 5px !important;
}
.padding-right-20 {
  padding-right: 20px;
}
.small-margin-top {
  margin-top: 7px;
}
.btn.default {
  background: #FFFFFF !important;
}
.btn.default.btn-outline {
  border: solid 1px #cccccc;
  background: #FFFFFF;
  color: #000000;
}
.btn.default.btn-outline:hover {
  background: #cccccc;
}
.btn.secondary.btn-outline {
  border: solid 1px #cccccc;
}
.task-block > * {
  width: 140px;
  height: 180px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px !important;
  float: left;
  text-align: center;
}
.task-block {
  display: inline-block;
}
.task-block .start {
  display: none;
  color: white;
  font-size: 24pt;
  font-weight: bold;
  line-height: 3;
  text-decoration: none;
}
.heroicon-couple2-female-top {
  fill: #dddddd;
}
.heroicon-couple2-male-collar {
  fill: #FFFFFF;
}
.heroicon-calendar-date {
  fill: #F3565D;
}
.heroicon-couple2-female-hair {
  fill: #F3565D;
}
div.block-title {
  text-align: center;
  font-weight: bold;
  font-size: 16pt;
}
@media (min-width: 768px) and (max-width: 1050px) {
  .task-block > * {
    width: 120px;
    height: 160px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 4px !important;
    float: left;
    text-align: center;
  }
  div.block-title {
    font-size: 14pt;
  }
}
div.block-number {
  text-align: center;
  font-size: 64pt;
  font-weight: bold;
  line-height: 1.1;
}
hr.sidebar {
  margin: 4px;
  padding: 0px;
  background: #eeeeee;
  color: #333333;
  border-radius: 10px 10px 10px 10px !important;
  padding: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.btn.secondary.btn-outline:hover {
  background: #cccccc;
}
.nav.nav-pills li {
  display: inline-block;
  float: none;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background: #FFFFFF !important;
  font-weight: bold;
}
div.long-read {
  padding-left: 30px;
  padding-right: 30px;
}
div.long-read p {
  line-height: 1.7;
}
h1 {
  font-size: 23px;
  font-weight: bold;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 16px;
}
div.row div.long-read {
  padding-left: 0px;
  padding-right: 0px;
}
ul.reference-list {
  margin: 0px;
  padding: 0px;
}
ul.reference-list li {
  display: block;
  list-style: none;
  border: solid 1px #cccccc;
  padding: 5px;
  margin: 5px;
}
div.structure h3 {
  color: #222222;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 8px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
div.structure .cut-off {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
  display: block;
}
div.breadcrumb {
  margin: 0px;
  padding: 0px;
}
div.breadcrumb ul {
  list-style-type: none;
  padding: 0px;
}
div.breadcrumb li {
  display: inline;
  color: #222222;
}
div.breadcrumb li:before {
  content: "»";
  font-weight: bold;
  margin-left: 5px;
  margin-right: 10px;
}
.iframe-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
h1.lead {
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
p.byline {
  margin-top: -15px;
  font-size: 14px;
  color: #777777;
}
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-left: none;
  border-right: none;
}
.table-scrollable,
.table-bordered {
  border-left: none;
  border-right: none;
}
.label-info {
  color: #155724;
  background-color: #d4edda;
}
.label-warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
}
.label-danger {
  color: #721c24;
  background-color: #f8d7da;
}
span.label {
  border-radius: 5px !important;
  display: block-inline;
}
span.label-sm {
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-weight: bold;
}

/*# sourceMappingURL=default.css.map */